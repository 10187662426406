import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/components/dropdown/accessibility",
  "title": "Dropdown - Accessibility"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "accessibility",
      "style": {
        "position": "relative"
      }
    }}>{`Accessibility`}<a parentName="h2" {...{
        "href": "#accessibility",
        "aria-label": "accessibility permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "pay-attention-to",
      "style": {
        "position": "relative"
      }
    }}>{`Pay attention to`}<a parentName="h3" {...{
        "href": "#pay-attention-to",
        "aria-label": "pay attention to permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`Dropdowns should be only used as form controls or as value pickers. Do not use dropdowns for menus or navigation since they cannot contain links or buttons.`}</li>
      <li parentName="ul">{`Do not use context change triggers (e.g. links) as dropdown options. See WCAG 3.2.2 On Input guideline for more information.`}</li>
      <li parentName="ul">{`Placeholder text can be used to give hints and examples to the user what is being selected with the dropdown. `}<strong parentName="li">{`However, all assistive technologies do not treat placeholder texts as labels and therefore they may ignore them completely.`}</strong>{` Aim to provide the sufficient information to fill the input in the label or in the assistive text. Also if possible, set default option for the dropdown so the placeholder is not needed.`}</li>
      <li parentName="ul">{`In the case of an erroneous selection, aim to provide clear instructions to the user how to correct the mistake. Always provide text description of the error. In dropdowns it is usually possible to filter selectable options for the user to avoid errors completely.`}</li>
      <li parentName="ul">{`HDS Dropdowns support getA11ySelectionMessage that should be included to give screen reader better feedback when an option is selected.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      